html {
  background-color: #323747;
}

.App {
  text-align: center;
  background-color: #323747;
  color: white;
  height: 100vh;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tile {
  box-shadow: 2px 2px 6px -1px #000;
  background: radial-gradient(circle, rgba(50,55,71,1) 0%, rgba(0,0,0,1) 600%);
}

.tile label {
  font-size: 20px;
  font-weight: bold;
}

.tile p {
  font-size: 14px;
}

.tile:hover {
  background: unset;  
  background-color: #F87C59;
  cursor: pointer;
}
